<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      v-on:input="updateValue($event)"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="$emit('update:dialog',false)">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Refund</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-title class="headline"></v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" md="3">
              <v-radio-group
                label="Cash"
                v-model="refund.moneyType"
                prepend-icon="money"
                row
                hide-details
              >
                <v-radio label="MMK" value="mmk"></v-radio>
                <v-radio label="USD" value="usd"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12">
              <template v-for="(item, index) in refund.refundWay">
                <v-row
                  no-gutters
                  :key="index"
                  style="border: 1px solid rgba(0, 0, 0, 0.12);"
                  class="pa-2 mt-1"
                >
                  <v-col cols="12">
                    <v-row dense>
                      <v-col cols="12" md="3">
                        <v-autocomplete
                          v-model="item.forPerson"
                          :items="listForName"
                          multiple
                          dense
                        />
                      </v-col>

                      <v-col cols="4" md="3">
                        <v-autocomplete
                          v-model="item.pnr"
                          :items="listPnr"
                          label="PNR"
                          dense
                          multiple
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="4" md="2">
                        <v-text-field
                          v-model.number="item.fare"
                          type="Number"
                          label="Price"
                          @keyup="item.amount = item.pass* item.fare"
                        />
                      </v-col>
                      <v-col cols="4" md="2">
                        <v-text-field
                          v-model.number="item.pass"
                          type="Number"
                          label="Qty"
                          @keyup="item.amount = item.pass* item.fare"
                        />
                      </v-col>
                      <v-col cols="4" md="2">
                        <v-text-field
                          v-model.number="item.amount"
                          type="Number"
                          label="Amount"
                          readonly
                        />
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        Refund For {{item.pnr}}
                        <br />
                        {{item.travelDate}} ({{item.fromSector.shortName}}-{{item.toSector.shortName}})
                        <br />
                        {{item.forPerson | commaString}}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </v-col>
            <v-col cols="12" md="12">
              <v-row no-gutters>
                <v-col cols="12" md="3" class="mt-3 ">
                  <v-textarea rows="1" label="Description" v-model="refund.description" dense></v-textarea>
                </v-col>
                <v-col cols="12" md="2" class="mt-3 ml-3" >
                  <v-text-field v-model.number="refund.desprice" type="number" label="Price" dense/>
                </v-col>
                <v-col cols="12" md="2" class="mt-3 ml-3" >
                  <v-text-field v-model.number="refund.desqty" type="number" label="Qty" dense/>
                </v-col>
                <v-col cols="12" md="2" class="mt-3 ml-3" >
                  <v-text-field v-model.number="desctotalprice" type="number" label="Total Price" dense readonly/>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12" class="mt-3">
              <v-row no-gutters>
                <v-col cols="12" md="3">
              <v-text-field v-model.number="totalAmount" type="number" label="TotalAmount" dense/>
              </v-col>
              <v-col cols="12" md="3" class="ml-3">
              <v-text-field v-model.number="refund.paid" type="number" label="TotalPaid" dense/>
              </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters>
              <v-col cols="12" md="3" class="ml-2">
                  <v-textarea label="Remark" rows="2" v-model="refund.remark" dense></v-textarea>
              </v-col>
              </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="4" v-if="refund.refundId==0 || refund.refundId==undefined">
              <v-btn color="primary" @click="saveRefundVoucher">Save RefundedVoucher</v-btn>
            </v-col>
            <v-col cols="12" md="4" v-else>
              <v-btn color="primary" @click="updateRefundVoucher">Update RefundedVoucher</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import voucherService from "../service/VoucherService";

export default {
  data() {
    return {
      selectedListForName: "",
      desctotalprice:0
    };
  },
  methods: {
    saveRefundVoucher: function() {
      voucherService
        .saveRefundVoucher(this.voucher.voucherId, this.refund)
        .then(data => {
          this.$swal2
            .fire({
              icon: "success",
              title: "Refund Voucher success",
              showConfirmButton: false,
              timer: 1500
            })
            .then(v => {
              this.$emit("update:dialog", false);
              this.saveRefund();
            });
        });
    },
    updateRefundVoucher: function() {
      voucherService
        .updateRefundVoucher(
          this.voucher.voucherId,
          this.refund.refundId,
          this.refund
        )
        .then(data => {
          this.$swal2
            .fire({
              icon: "success",
              title: "Refund Voucher updated.",
              showConfirmButton: false,
              timer: 1500
            })
            .then(v => {
              this.$emit("update:dialog", false);
              this.updateRefund();
            });
        }).catch(e => {
          this.$swal2.fire({
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false
          });
        });
    }
  },
  props: {
    dialog: {
      type: Boolean
    },
    refund: {
      type: Object,
      default: () => {
        return {
          desprice:Number,
          desqty:Number,
          refundCode: String,
          remark: String,
          usdTotal: Number,
          mmkTotal: Number,
          moneyType: String, //mmk,usd
          refundWay: {
            type: Array,
            default: () => {
              return [
                {
                  forPerson: String,
                  pass: Number,
                  fare: Number,
                  pnr: String,
                  amount: Number
                }
              ];
            }
          }
        };
      }
    },
    voucher: Object,
    saveRefund: Function,
    updateRefund: Function
  },
  computed: {
    listForName: function() {
      let listResult = this.voucher.forPerson.map(v => {
        return v.pass.passTypeName + v.name;
      });
      return listResult;
    },
    listPnr: function() {
      return this.voucher.pnr.split(",");
    },
    totalAmount: function() {
      let total = this.desctotalprice;
      this.refund.refundWay.forEach(value => {
        total += value.amount;
      });
      return total;
    },
  },
  filters: {
    commaString: function(value) {
      if (value == null || value == "") return "";
      return value.join();
    }
  },
  watch: {
    totalAmount: function(value) {
      this.refund.total = value;
    },
    "refund.desprice":function(){
        this.desctotalprice = Number(this.refund.desprice)*Number(this.refund.desqty);
    },
    "refund.desqty":function(){
        this.desctotalprice = Number(this.refund.desprice)*Number(this.refund.desqty);
    },
    desctotalprice: function() {
      this.totalAmount = this.desctotalprice;
    },
  }
};
</script>

<style scoped>
</style>