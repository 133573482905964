import BasicService from "./BasicService";

class VoucherService extends BasicService {
  constructor() {
    super();
  }

  getVoucherReceiveList({ fromDate, toDate, customerId = 0, type }) {
    let url = `/reports/customers/${customerId}/detail`;
    return this.axios
      .get(url, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          type: type
        }
      })
      .then(response => response.data);
  }

  receivedVoucher(vocuherReceive) {
    let url = `/payments/receive`;
    return this.axios.post(url, vocuherReceive).then(request => request.data);
  }

  getReceiveVoucher(vocuherId) {
    let url = `/vouchers/${vocuherId}/changes`;
    return this.axios.get(url).then(request => request.data);
  }

  getVoucherHistoryChange(vocuherId) {
    let url = `/vouchers/${vocuherId}/changes`;
    return this.axios.get(url).then(request => request.data);
  }

  getExpenseVoucherDataForPrint(vocuherId, refundId) {
    let url = `/vouchers/${vocuherId}/refund/${refundId}`;
    return this.axios.get(url).then(request => request.data);
  }

  getVoucherDataForPrint(vocuherId, code) {
    let url = `/vouchers/${vocuherId}/detail`;
    return this.axios
      .get(url, {
        params: {
          code
        }
      })
      .then(request => request.data);
  }

  getVoucherHistory(vocuherId) {
    let url = `/vouchers/${vocuherId}/history`;
    return this.axios.get(url).then(request => request.data);
  }

  deleteVoucher(vocuherId) {
    let url = `/vouchers/${vocuherId}`;
    return this.axios.delete(url).then(request => request.data);
  }

  getVoucherList(fromDate, toDate) {
    let url = `/vouchers`;
    return this.axios
      .get(url, {
        params: {
          fromDate,
          toDate
        }
      })
      .then(request => request.data);
  }
  getVouchersAll(fromDate, toDate) {
    let url = `/vouchers/all`;
    return this.axios
      .get(url, {
        params: {
          fromDate,
          toDate
        }
      })
      .then(request => request.data);
  }
  getVoucherByVocucherCode(vocuherId) {
    let url = `/vouchers/${vocuherId}`;
    return this.axios.get(url).then(request => request.data);
  }

  getVoucherByPnr(pnr) {
    let url = `/vouchers/pnr`;
    return this.axios
      .get(url, {
        params: {
          pnr
        }
      })
      .then(request => request.data);
  }

  getVoucherId(vocuherId) {
    let url = `/vouchers/${vocuherId}/detail`;
    return this.axios.get(url).then(request => request.data);
  }

  saveVoucher(voucher) {
    let url = `/vouchers`;
    return this.axios.post(url, voucher).then(request => request);
  }

  /**
   *
   * @param {Number} voucherId
   * @param {Voucher} voucher
   */
  updateVoucher(voucherId, voucher) {
    let url = `/vouchers/${voucherId}`;
    return this.axios.put(url, voucher).then(request => request.data);
  }

  nameChange(voucherId, changeVoucher) {
    let url = `/vouchers/${voucherId}/nameChange`;
    return this.axios.post(url, changeVoucher).then(response => response.data);
  }

  saveDateChange(voucherId, voucher) {
    let url = `/vouchers/${voucherId}/dateChange`;
    return this.axios.post(url, voucher).then(response => response.data);
  }

  saveSectorChange(voucherId, voucher) {
    let url = `/vouchers/${voucherId}/sectorChange`;
    return this.axios.post(url, voucher).then(response => response.data);
  }

  saveDateAndSectorChange(voucherId, voucher) {
    let url = `/vouchers/${voucherId}/dateAndSectorChange`;
    return this.axios.post(url, voucher).then(response => response.data);
  }

  updateSectorChange(voucherId, voucher) {
    let url = `/vouchers/${voucherId}/sectorChange`;
    return this.axios.put(url, voucher).then(response => response.data);
  }

  updateDateChange(voucherId, voucher) {
    let url = `/vouchers/${voucherId}/dateChange`;
    return this.axios.put(url, voucher).then(response => response.data);
  }

  updateDateAndSectorChange(voucherId, voucher) {
    let url = `/vouchers/${voucherId}/dateAndSectorChange`;
    return this.axios.put(url, voucher).then(response => response.data);
  }

  saveRefundVoucher(voucherId, refund) {
    let url = `/vouchers/${voucherId}/refund`;
    return this.axios.post(url, refund).then(response => response.data);
  }

  getLastVoucherById(voucherId) {
    let url = `/vouchers/${voucherId}/left`;
    return this.axios.get(url).then(response => response.data);
  }

  getNameChangeByChangesId({ voucherId, changesId }) {
    let url = `/vouchers/${voucherId}/changes/${changesId}`;
    return this.axios.get(url).then(response => response.data);
  }

  nameChangeUpdate({ voucherId, changesId, voucher }) {
    let url = `/vouchers/${voucherId}/changes/${changesId}`;
    return this.axios.put(url, voucher).then(response => response.data);
  }
  getVocuherCashbackForPrint(voucherId) {
    let url = `/vouchers/${voucherId}/cashback`;
    return this.axios.get(url).then(response => response.data);
  }

  getVoucherLogById(voucherId) {
    let url = `/vouchers/log/${voucherId}.txt`;
    return this.axios.get(url).then(response => response.data);
  }

  getPaymentReceiveListByVoucherMaster({ fromDate, toDate, customerId = 0, type,voucherType }) {
    let url = `/payments/customers/${customerId}/detail`;
    return this.axios
      .get(url, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          type: type,
          voucherType: voucherType,
        }
      })
      .then(response => response.data);
  }
  getPaymentReceiveListByVoucherChange({ fromDate, toDate, customerId = 0, type,voucherType }) {
    let url = `/payments/customers/${customerId}/detail`;
    return this.axios
      .get(url, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          type: type,
          voucherType: voucherType,
        }
      })
      .then(response => response.data);
  }

  getRefundById(voucherId = 0, refundId = 0) {
    let url = `/vouchers/${voucherId}/refund/${refundId}`;
    return this.axios.get(url).then(response => response.data);
  }
  updateRefundVoucher(voucherId, refundId, refund) {
    let url = `/vouchers/${voucherId}/refund/${refundId}`;
    return this.axios.put(url, refund).then(response => response.data);
  }
  deleteVouchersCode(code,voucherId,remark) {
    let url = `/vouchers/code/${code}/${voucherId}`;
    return this.axios.delete(url,{
      params:{
        remark
      }
    }).then(request => request.data);
  }
}

const expenseReportService = new VoucherService();
export default expenseReportService;
